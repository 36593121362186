<template>
  <div>
    <Header />

    <div class="login-register_area">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-8 col-xs-12 col-lg-6">
            <form action class="form" @submit.prevent="login">
              <div class="login-form">
                <h4 class="login-title">Iniciar sesión</h4>
                <div class="row">
                  <div class="col-md-12 col-12">
                    <label>Email *</label>
                    <input
                      v-model="email"
                      type="email"
                      id="email"
                      required
                      placeholder="Ingresa tu correo electrónico"
                      autocomplete="username email"
                    />
                  </div>
                  <div class="col-12 mb--20">
                    <label>Contraseña *</label>
                    <input
                      v-model="password"
                      id="password"
                      required
                      :type="passwordFieldType"
                      autocomplete="curent-password"
                      placeholder="Ingresa tu contraseña"
                    />
                    <a
                      @click="switchVisibility()"
                      style="position: absolute; right: 20px"
                      class="notunderlined"
                      v-if="passwordFieldType == 'password'"
                    >
                      <i class="fa fa-eye" style="vertical-align: middle"></i>
                    </a>
                    <a
                      @click="switchVisibility()"
                      style="position: absolute; right: 20px"
                      class="notunderlined"
                      v-if="passwordFieldType == 'text'"
                    >
                      <i class="fa fa-eye-slash" style="vertical-align: middle"></i>
                    </a>
                  </div>
                  <div class="col-md-6">
                    <div class="check-box">
                      <input
                        type="checkbox"
                        id="remember_me"
                        checked
                        v-model="remember_me"
                      />
                      <label for="remember_me">Recuérdame</label>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="forgotton-password_info">
                      <router-link
                        to="/restablecer"
                        style="float: right; text-transform: initial"
                        >¿Olvidaste tu contraseña?</router-link
                      >
                    </div>
                  </div>
                  <div class="col-md-12">
                    <button type="submit" class="btn ht-btn--round">Ingresar</button>
                    <p v-if="error" class="error" style="padding-top: 10px">
                      La combinación de email y contraseña es incorrecta.
                    </p>
                    <p v-if="error" class="error text-danger">
                      Si todavía no tienes una cuenta, te invitamos a registrarte
                    </p>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="col-sm-12 col-md-8 col-xs-12 col-lg-6">
            <div class="login-form">
              <h4 class="login-title">Nuevo cliente</h4>
              <div class="row">
                <p>
                  Regístrese para obtener una cuenta gratuita en nuestro ecosistema.
                  Registrarse es facil y rápido.
                </p>
                <div class="col-md-12">
                  <router-link to="/signup" class="btn ht-btn--round"
                    >Regístrate</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <BrandCarousel />

    <Footer />

    <OffCanvasMobileMenu />

    <!-- back to top start -->
    <back-to-top class="scroll-top" bottom="60px">
      <i class="ion-android-arrow-up"></i>
    </back-to-top>
    <!-- back to top end -->
  </div>
</template>

<script>
import Header from "@/components/Header";
import BrandCarousel from "../components/BrandCarousel";
import Footer from "../components/Footer";
import OffCanvasMobileMenu from "@/components/OffCanvasMobileMenu";
import axios from "axios";

export default {
  data: () => ({
    email: "",
    password: "",
    remember_me: false,
    cartItemsNumber: 0,
    passwordFieldType: "password",
    error: false,
  }),
  name: "Login",
  components: {
    Header,
    BrandCarousel,
    Footer,
    OffCanvasMobileMenu,
  },
  created() {
    this.isLoggedIn();
  },
  methods: {
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    isLoggedIn() {
      if (sessionStorage.getItem("token") != null) {
        axios
          .get(process.env.VUE_APP_AUTH_API + "api/auth/user", {
            headers: {
              Authorization: `Bearer ` + sessionStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.$router.go(-1);
          })
          .catch((error) => {
            sessionStorage.clear();
            //console.log(error);
          });
      }
    },
    getCartData() {
      axios
        .get(process.env.VUE_APP_CART_API + "api/platform/12/user/cart", {
          headers: {
            Authorization: `Bearer ` + sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          if (response) {
            if (response.data.order != null) {
              if (!sessionStorage.getItem("order")) {
                sessionStorage.setItem("order", JSON.stringify(response.data.order));
                sessionStorage.setItem("items", response.data.order.items.length);
              } else {
                sessionStorage.removeItem("order");
                sessionStorage.removeItem("items");
                sessionStorage.setItem("order", JSON.stringify(response.data.order));
                sessionStorage.setItem("items", response.data.order.items.length);
              }
            } else {
              sessionStorage.setItem("order", 0);
              sessionStorage.setItem("items", 0);
            }
          }
        })
        .catch((error) => {
          this.$swal({
            title: "Ocurrió un problema",
            text:
              "No pudimos obtener tus ordenes de compra. Comprueba tu conexión de internet e intenta nuevamente",
            icon: "error",
            showCancelButton: false,
            confirmButtonColor: "#e94e1b",
            showConfirmButton: true,
          }).then((result) => {
            if (result.isConfirmed) {
              location.reload();
            }
          });
          this.$swal.showValidationMessage(`Solicitud rechazada: ${error}`);
          //console.log(error);
        });
    },

    async getOrders() {
      axios
        .get(process.env.VUE_APP_CART_API + "api/orders/12/all", {
          headers: {
            Authorization: `Bearer ` + sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          sessionStorage.setItem("ordersNumber", response.data.orders.length);
          setTimeout(() => this.$router.go(-1), 3000);
        })
        .catch((error) => {
          console.log(error.response);
          this.error = true;
        });
    },
    getUserData() {
      axios
        .get(process.env.VUE_APP_AUTH_API + "api/auth/user", {
          headers: {
            Authorization: `Bearer ` + sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          //console.log(response.data);
          sessionStorage.setItem("name", response.data.name);
          sessionStorage.setItem("lastname", response.data.user_lastname);
        })
        .catch((error) => {
          this.$swal({
            title: "Ocurrió un problema",
            text: "Comprueba tu conexión de internet e intenta nuevamente",
            icon: "error",
            showCancelButton: false,
            confirmButtonColor: "#e94e1b",
            showConfirmButton: true,
          }).then((result) => {
            if (result.isConfirmed) {
              location.reload();
            }
          });
          console.log(error);
        });
    },

    login() {
      document.body.style.cursor = "wait";
      axios
        .post(process.env.VUE_APP_AUTH_API + "api/auth/login", {
          email: this.email,
          password: this.password,
          remember_me: this.remember_me,
        })
        .then((response) => {
          let token = response.data.access_token;
          sessionStorage.setItem("token", token);
          this.getUserData();
          this.getCartData();
          this.getOrders();
          this.$swal({
            imageWidth: 300,
            imageUrl: "/assets/img/logo/services-like-u-group.svg",
            title: `Te damos la Bienvenida`,
            timer: 3000,
            showCancelButton: false,
            showConfirmButton: false,
          });
        })
        .catch((error) => {
          this.$swal({
            title: "Ocurrió un problema",
            text: "La combinación de usuario y contraseña es incorrecta",
            icon: "error",
            showCancelButton: false,
            confirmButtonColor: "#e94e1b",
            showConfirmButton: true,
          }).then((result) => {
            if (result.isConfirmed) {
              location.reload();
            }
          });
          console.log(error);
          this.error = true;
        });
      document.body.style.cursor = "default";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.ht-btn--round {
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 20px;
  font-size: 20px;
  background-color: #e94e1b;
}
</style>
